import React from "react";
import { navigate } from "gatsby";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { Button } from "@material-ui/core";
import SEO from "./seo";

export default () => {
  const [state, setState] = React.useState({
    llcode: "",
  });

  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      navigate(`/quotes/quote/?${state.llcode}`);
    },
    [state.llcode]
  );

  return (
    <>
      <SEO
        title="LabLog ELN: Get Quote"
        description="Use this form to lookup your customized quote."
      />

      <Container maxWidth="md" style={{ paddingTop: "30px" }}>
        <Typography component="div">
          <Box
            textAlign="left"
            component="h4"
            m={1}
            fontFamily="DM Serif Text"
            fontWeight="700"
            fontSize="h4.fontSize"
          >
            LabLog
            <sup>&#174;</sup>
          </Box>
          <Box
            textAlign="left"
            component="h1"
            m={1}
            fontFamily="DM Serif Text"
            fontWeight="700"
            fontSize="h3.fontSize"
          >
            Order Form
          </Box>
          <br />
          <Container maxWidth="sm">
            <Box component="p" textAlign="left">
              Enter quote reference number to view the sales agreement for your
              business.
            </Box>
          </Container>
        </Typography>
        <br />
        <br />
        <form onSubmit={handleSubmit}>
          <Container maxWidth="sm">
            <Grid container spacing={3}>
              <FormControl
                fullWidth
                style={{ marginBottom: "30px", borderColor: "white" }}
              >
                <InputLabel style={{ color: "white" }} htmlFor="name-input">
                  Enter code here
                </InputLabel>
                <Input
                  id="llcode"
                  variant="filled"
                  required
                  onChange={(e) => {
                    setState({ llcode: e.target.value });
                    document.getElementById("component-error-text").innerText =
                      "";
                  }}
                />
              </FormControl>
              <p id="component-error-text" />
            </Grid>
          </Container>
          <br />
          <br />
          <br />
          <Container maxWidth="xs">
            <Button variant="contained" type="submit" color="primary" fullWidth>
              View Agreement
            </Button>
          </Container>
        </form>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
    </>
  );
};
